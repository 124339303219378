<template>
    <CCard class="users-list">
        <teleport v-if="mountedComponent" to="#users-header">
            <h1 class="users-list__heading">Users</h1>
        </teleport>

        <teleport v-if="mountedComponent" to="#header-nav">
            <CBreadcrumbItem active>Users</CBreadcrumbItem>
        </teleport>

        <CCardBody>
            <div class="d-flex mb-3 flex-wrap">
                <CRow class="gx-0 gx-lg-3 justify-content-start w-100">
                    <CCol class="GCFilterMaxWidth col-12 col-md-12 col-lg-9 col-xl-10 col-xxl-10">
                        <GCFilter component="Users" :except-column="[]"
                                  @search="(val) => onTimeoutSearch(() => fetchData(val))"/>
                    </CCol>
                    <CCol class="col-12 col-md-12 col-lg-3 col-xl-2 col-xxl-2 text-lg-center mt-3 mt-lg-0 bulkAction">
                        <CDropdown :key="`${canSendEmail}-${selectedUsers.length}`" color="primary"
                                   class="mb-3 mr-3 search-field w-100">
                            <CDropdownToggle class="d-flex align-items-center justify-content-between" color="primary"
                                             variant="outline" :disabled="!selectedUsers.length">
                                Bulk Action
                            </CDropdownToggle>
                            <CDropdownMenu>
                                <CDropdownItem @click="handleSendCommunication">
                                    Send Email
                                </CDropdownItem>
                                <CDropdownItem @click="exportUsers">
                                    Export Users
                                </CDropdownItem>
                            </CDropdownMenu>
                        </CDropdown>
                    </CCol>
                </CRow>
            </div>
            <SmartTable :items="users" :items-per-page="parseInt(perPage)" :columns="fields" :sorter-value="sort"
                        selectable="id" :selected-items="selectedUsers" :loading="loading" :active-page="options.page"
                        :per-page="options.per_page" :pages="options.last_page"
                        :clear-text=" options.search?.length ? 'No search results found' : 'No Data yet'"
                        @sorter-change="changeSort" @selected-items-change="onSelect"
                        @update-active-page="(value) => changePage(value)"
                        @update-per-page="(value) => changePerPage(value)">
                <template v-if="!search?.length" #clear_text>
                    <div class="table__clear d-flex align-items-center justify-content-center mb-2 w-100">
                        <CIcon class="table__clear__icon" size="6xl" name="cilUser"/>
                        <div class="table__clear__title">No Users yet</div>
                        <div class="table__clear__title-sub">
                            All users you have added will be displayed here.
                        </div>
                        <CButton color="primary" class="table__clear__btn" @click="handleInviteEmployee">
                            Add user
                        </CButton>
                    </div>
                </template>
            </SmartTable>
        </CCardBody>
    </CCard>
</template>

<script>
import {getVerUri} from '@/services/ApiService'
import permissions from '@/utils/constants/permissions'
import GCFilter from '@/components/GCFilter'
import {OPEN_TASKS} from "@/domain/Entities/Task/taskStatuses";
import Gate from '@/services/Gate/Gate'
import {notifyByPlan, permissionError} from '@/services/Notify/Toasts'
import {downloadFile} from '@/utils/files'
import onTimeoutSearch from '@/mixin/searchTimeout'

export default {
    name: 'Users',
    mixins: [onTimeoutSearch],
    components: {
        GCFilter,
    },
    data() {
        return {
            mountedComponent: false,
            key: 0,
            search: '',
            selectedUsers: [],
            fields: [
                {
                    key: 'full_name',
                    label: 'Name',
                    link: {
                        name: 'CompanyUser',
                        params: {user_id: 'id', id: 'company_id'},
                    },
                },
                {
                    key: 'company_name',
                    label: 'Company',
                    link: {name: 'Company', params: {id: 'company_id'}},
                },
                {
                    key: 'projects_count',
                    label: 'Projects',
                    link: {
                        name: 'CompanyUserProjects',
                        params: {user_id: 'id', id: 'company_id'},
                    },
                    format: (v) => `${v}`,
                },
                {
                    key: 'tasks_count',
                    label: 'Open Tasks',
                    link: {
                        name: 'CompanyUserTasks',
                        params: {user_id: 'id', id: 'company_id'},
                        query: {status: OPEN_TASKS},
                    },
                    format: (value) => `${value}`,
                },
                {
                    key: 'overdue_tasks_count',
                    label: 'Overdue Tasks',
                    link: {
                        name: 'CompanyUserTasks',
                        params: {user_id: 'id', id: 'company_id'},
                        query: {is_overdue: true},
                    },
                    format: (value) => `${value}`,
                },
                {key: 'email', label: 'Email', sorter: false, email: true},
                {key: 'phone', label: 'Phone', sorter: false, email: 'tel'},
                {key: 'job_title', label: 'Job Title', sorter: false},
                {key: 'company_office_address', label: 'Address', sorter: false},
            ],
            users: [],
            options: {
                per_page: 10,
                page: 1,
                last_page: 1,
                order: 'desc',
                order_by: 'tasks_count',
            },
            sort: {column: 'tasks_count', state: 'desc'},
            loading: true,
        }
    },
    computed: {
        canSendEmail() {
            return this.authUser.can(permissions.USERS_COMMUNICATION)
        },
    },
    created() {
        this.$nextTick(() => {
            if (!this.$route.query.search) this.fetchData()
        })
    },
    mounted() {
        this.mountedComponent = true
    },
    methods: {
        onSelect(users) {
            this.selectedUsers = users
            this.$emit('onSelect', this.selectedUsers)
        },
        async handleInviteEmployee() {
            const check = await Gate.can('create', 'employee')
            if (!check) {
                this.$notify(permissionError)
                return false
            }
            this.$router.push({name: 'InviteEmployee'})
        },
        async handleSendCommunication() {
            const check = await Gate.can('communication', 'employee')
            if (!check) {
                notifyByPlan()
                return false
            }
            this.$router.push({
                name: 'SendCommunication',
                query: {users: this.selectedUsers},
            })
        },
        fetchData(f = {}) {
            this.loading = true
            this.options.search = this.search = f.search || ''
            this.axios
                .get(getVerUri('users/users'), {params: {...f, ...this.options}})
                .then(({data}) => {
                    this.key++
                    this.users = data.data
                    this.options.last_page = data.meta.last_page
                })
                .finally(() => (this.loading = false))
        },
        changePage(val) {
            this.options.page = val
            this.fetchData()
        },
        changePerPage(val) {
            this.options.per_page = val
            this.options.page = 1
            this.fetchData()
        },
        changeSort(val) {
            this.options.order_by = val.column
            this.options.order = val.state
            this.options.order = val.state
            this.sort = val
            this.fetchData()
        },
        exportUsers() {
            let params = this.$deepClone(this.options)
            params['users_id'] =
                this.selectedUsers.length > 0 ? this.selectedUsers : []
            this.$http.users
                .exportUsers(params)
                .then((response) => downloadFile(response, 'users.xlsx'))
        },
    },
}
</script>

<style scoped>
div >>> .scrollTable th {
    white-space: nowrap;
}

div >>> .scrollTable th span {
    float: none !important;
    margin-left: 5px;
}

div >>> table.table.table-hover.table-striped + div {
    position: relative;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
</style>

<style lang="scss" scoped>
.users-list {
    &__heading {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.2px;
        color: #1c262f;
    }
}

.table__clear {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px 30px 60px;
    background: #f5f7f9;

    &__icon {
        color: #b1b7c1;
        margin-bottom: 20px;
    }

    &__title {
        margin-bottom: 12px;
        font-weight: 700;
        font-size: 18px;
        color: #303c54;

        &-sub {
            margin-bottom: 35px;
            font-weight: 400;
            font-size: 14px;
            color: #465967;
        }
    }
}

@media (max-width: 767px) {
    .search-field {
        width: 100%;
        min-width: 100%;
    }
}
</style>
